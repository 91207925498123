import { useTranslation } from 'react-i18next';
import { IconButton } from '@mui/material';
import { DeleteOutline } from '@mui/icons-material';
import * as Styled from '../../styled';
import { GetFileIcon } from './getFileIcon';

export const ShowUploadedFile = ({
  value,
  readOnly,
  setValue,
}: {
  value: string;
  readOnly: boolean;
  setValue: (props: any) => void;
}) => {
  const { t } = useTranslation();

  const valueArr = value.split('?');
  const link = valueArr[0] ?? '';
  const params = valueArr[1] ?? '';

  const nameParam = params?.split('&')?.[0] ?? '';
  const name = nameParam?.split('=')?.[1] ?? '';

  const extentionArr = link?.split('.') ?? [];
  const extention = extentionArr[extentionArr.length - 1] ?? '';

  return (
    <Styled.UploadedFile>
      <GetFileIcon extention={extention.toLowerCase()} />

      <a href={link} target="_blank">
        {decodeURI(name) ?? t('File')}
      </a>

      {!readOnly && (
        <IconButton onClick={() => setValue(null)}>
          <DeleteOutline />
        </IconButton>
      )}
    </Styled.UploadedFile>
  );
};
