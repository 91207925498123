import { IconButton, Tooltip } from '@mui/material';
import i18n from '@shared/config/i18n';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';

import { StyledToolbar, StyledToolbarHeader } from '../toolbar-list/styled';
import { StyledTypography } from './styled';

type Props = {
  title: string;
  onBack: () => void;
  onClose: () => void;
};

export const ToolbarCard = ({ onClose, onBack, title }: Props) => (
  <StyledToolbar>
    <StyledToolbarHeader>
      <Tooltip title={i18n.t('Back')}>
        <IconButton onClick={onBack}>
          <ArrowBackOutlinedIcon />
        </IconButton>
      </Tooltip>
      <StyledTypography variant="h5">{title}</StyledTypography>
      <Tooltip title={i18n.t('close')}>
        <IconButton onClick={onClose}>
          <CloseOutlinedIcon />
        </IconButton>
      </Tooltip>
    </StyledToolbarHeader>
  </StyledToolbar>
);
