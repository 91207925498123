import type { NewsFeedResponse } from '../models';

const identifyNewIds = (
  oldRow: number[],
  response: NewsFeedResponse
): NewsFeedResponse => ({
  ...response,
  news: response.news
  .filter((item) => !Boolean(oldRow.find((id) => id === item.id)))
})

export { identifyNewIds };