import Dropzone from 'dropzone';
import i18n from '@shared/config/i18n';
import { manualError } from '../model/upload.model';
import { Editor } from '../types';
import { handleUploadFile } from './uploadFile';

const { t } = i18n;

export const dropzoneInit = (id: string, fileTypes?: string[], editor?: Editor) =>
  new Dropzone(`label#dataupload_${id}`, {
    url: 'no_url_custom_sending_by_model',
    maxFiles: 1,
    autoProcessQueue: false,
    init: function () {
      this.on('addedfiles', () => {
        if (this.files.map((file) => file.name).length > 1) {
          manualError(new Error(t('DragSingleFile') as string));
          this.removeAllFiles();

          return;
        }
        handleUploadFile(id, this.files, fileTypes, editor);
        this.removeAllFiles();
      });
    },
  });
