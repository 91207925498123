import { Control } from '@effector-form';
import { useTranslation } from 'react-i18next';
import { Tooltip, IconButton } from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

import { searchForm } from '../../models';

import { StyledToolbar, StyledToolbarHeader, StyledTypography, StyledSearchForm } from './styled';

type Props = {
  onClose: () => void;
};

export const ToolbarList = ({ onClose }: Props) => {
  const { t } = useTranslation();

  return (
    <StyledToolbar>
      <StyledToolbarHeader>
        <StyledTypography variant="h5">{t('NewsFeed')}</StyledTypography>
        <Tooltip title={t('close')}>
          <IconButton onClick={onClose}>
            <CloseOutlinedIcon />
          </IconButton>
        </Tooltip>
      </StyledToolbarHeader>
      <StyledSearchForm>
        <Control.Search
          name="search"
          form={searchForm}
        />
      </StyledSearchForm>
    </StyledToolbar>
  )
};
