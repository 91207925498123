import { forwardRef, Ref } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import type { ScrollbarProps } from 'react-custom-scrollbars';
interface IProps extends ScrollbarProps {
  children?: JSX.Element | JSX.Element[];
  style?: object;
  autoHide?: boolean;
  trackVerticalStyle?: object;
  trackHorizontalStyle?: object;
  disableVerticalScrolling?: boolean;
}

const defaultVerticalStyle = {
  right: '0px',
  bottom: '4px',
  top: '4px',
  borderRadius: '6px',
  width: '10px',
  zIndex: 15,
};

const defaultHorizontalStyle = {
  right: '4px',
  bottom: '0px',
  left: '4px',
  borderRadius: '6px',
  height: '10px',
  zIndex: 15,
};

const CustomScrollbar = forwardRef(
  ({ children, ...props }: IProps, ref: Ref<Scrollbars>) => {
    const { trackVerticalStyle = {}, trackHorizontalStyle = {}, ...restProps } = props;

    const trackVertical = (props: { style: object }) => (
      <div
        {...props}
        style={{
          ...props.style,
          ...defaultVerticalStyle,
          ...trackVerticalStyle,
        }}
        className="track-vertical"
      />
    );
    const trackHorizontal = (props: { style: object }) => (
      <div
        {...props}
        style={{
          ...props.style,
          ...defaultHorizontalStyle,
          ...trackHorizontalStyle,
        }}
        className="track-horizontal"
      />
    );

    return (
      <Scrollbars
        autoHide
        hideTracksWhenNotNeeded
        autoHideTimeout={2000}
        renderTrackVertical={trackVertical}
        renderTrackHorizontal={trackHorizontal}
        style={{ ...props.style }}
        ref={ref}
        {...restProps}
      >
        {children}
      </Scrollbars>
    );
  }
);

export { CustomScrollbar };
