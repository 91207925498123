import { createEffect, createStore } from "effector";
import { newsFeedBadge } from '../../api';

import type { UnreadCountPayload, UnreadCountResponse } from './unread-count.types';

export const fxGetUnreadCount = createEffect<
  UnreadCountPayload,
  UnreadCountResponse,
  Error
>().use(newsFeedBadge.getUnreadCount);

export const $unreadCount = createStore<number>(0);
