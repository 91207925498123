import { FormControl, styled } from '@mui/material';

export const CustomFormControl = styled(FormControl)`
  display: flex;
  flex-direction: column;
`;

export const Form = styled('form')`
  display: flex;
  align-items: center;
  min-height: 56px;
`;

export const UploadedFile = styled('div')(() => ({
  display: 'inline-flex',
  alignItems: 'center',
  gap: '6px',
  '& > *': {
    flex: '1 0 auto',
    maxWidth: '250px',
    wordBreak: 'break-all',
  },
}));
