import { useTranslation } from 'react-i18next';
import * as Styled from './styled';

export const Hover = ({
  visible,
  value,
  id,
  setValue,
}: {
  visible: boolean;
  value: string | null;
  id: string;
  setValue: (value: any) => void;
}) => {
  const { t } = useTranslation();

  return (
    <Styled.CustomBackdrop open={visible}>
      <Styled.HoverItem htmlFor={`file-input_${id}`}>
        <Styled.AddPhotoAlternateOutlinedIcon />

        <Styled.Typography variant="body1">{t('Downloads')}</Styled.Typography>
      </Styled.HoverItem>

      {value && (
        <Styled.HoverItem onClick={() => setValue(null)}>
          <Styled.DeleteOutlineIcon />

          <Styled.Typography variant="body1">{t('remove')}</Styled.Typography>
        </Styled.HoverItem>
      )}
    </Styled.CustomBackdrop>
  );
};
