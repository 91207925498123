import styled from '@emotion/styled';
import { Theme } from '@mui/material/styles';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import { StyledComponentProps } from '@mui/material';
import { orange } from '@mui/material/colors';

type StyleBadgeType = {
  theme: Theme;
};

type ListItemButtonType = StyledComponentProps & {
  isRead: boolean;
  theme: Theme;
};

export const StyledPreviewCard = styled.div(({ theme }) => {
  return {
    padding: '12px',
    display: 'flex',
    cursor: 'pointer'
  }
});

export const StyleBadge = styled.span(({ theme }) => ({
  width: '8px',
  height: '8px',
  borderRadius: '50%',
  backgroundColor: theme.palette.primary.main,
  position: 'absolute',
  left: '16px',
  zIndex: 2
}));

export const StyledListItemButton = styled(ListItemButton)(({ theme, isNew }: any) => {
  const backgroundColor = isNew ? orange[200] : 'transparent';
  return {
    paddingLeft: '40px',
    paddingRight: '96px',
    backgroundColor,
    transition: '1s',
  }
});

export const StyledListItemText = styled(ListItemText)`
  padding-left: 16px;
  margin-right: 32px;

  & > span {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`;

export const StyledListItemAvatar = styled(ListItemAvatar)`
  width: 40px;
  height: 40px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  min-width: inherit;

  img {
    max-width: 40px;
    max-height: 40px;
    object-fit: cover;
    border-radius: 4px;
  }
`;

export const StyledPreviewContent = styled.div`
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3; 
  -webkit-box-orient: vertical;
`;
