import { sample } from 'effector';
import { onClickPreview, onClickBadge } from '@features/news-feed-badge';
import { PageGate, openNewsCard, setMode, toggleNewsFeed, $mode, resetParams } from './news-feed.model';

import { Mode } from './news-feed.types';

$mode
  .on(setMode, (_, mode) => mode)
  .reset([PageGate.close]);

/* при выборе новости, перехожу в детальную карточку записи */
sample({
  clock: openNewsCard,
  fn: (): Mode => Mode.NEWSCARD,
  target: setMode
});

/* при клике на поповер, перехожу сразу к детальной странице новости
  и открываю дровер */
sample({
  clock: onClickPreview,
  target: [
    setMode.prepend(() => Mode.NEWSCARD),
    toggleNewsFeed.prepend(() => true)
  ]
});

/* при закрытии дровера при статусе детальной страницы,
  выставляю режим список новостей и сбрасываю параметры на дефолтные */
sample({
  clock: toggleNewsFeed,
  filter: (isOpen) => !isOpen,
  fn: (): Mode => Mode.NEWSLIST,
  target: [setMode]
});

/* после клика на иконку с лентой новостей открываю дровер */
sample({
  clock: onClickBadge,
  fn: () => true,
  target: toggleNewsFeed
});

