import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useGate, useUnit } from 'effector-react';
import {
  Autocomplete,
  FormHelperText,
  FormLabel,
  TextField,
  CircularProgress,
} from '@mui/material';
import { Listbox } from '@features/effector-form/components/listbox';
import {
  Complex,
  ComplexesControlType,
} from '@features/effector-form/controls/complexes/types';
import { withControl } from '@features/effector-form/hoc/withControl';
import { ComplexesControlGate, complexesQuery } from './model';
import * as Styled from './styled';

export const ComplexesControl = withControl<ComplexesControlType>(
  ({
    value,
    setValue,
    error,
    label,
    required,
    readOnly,
    disabled,
    placeholder,
    isError,
  }) => {
    useGate(ComplexesControlGate);
    const { t } = useTranslation();

    const { data, pending } = useUnit(complexesQuery);

    const getOptions = () => {
      if (!data) return [];

      return data;
    };

    const getValue = () => {
      return options.filter(({ id }) => value.includes(id));
    };

    const options = getOptions();
    const values = getValue();

    useEffect(() => {
      setValue(values.map(({ id }) => id));
    }, [pending, readOnly]);

    return (
      <Styled.CustomFormControl required={required} disabled={disabled}>
        {label && <FormLabel error={isError}>{label}</FormLabel>}
        <Autocomplete
          multiple
          loading={pending}
          value={values}
          options={options}
          onChange={(event: any, newValue: any) => {
            setValue(newValue.map((item: Complex) => item.id));
          }}
          size="small"
          ListboxComponent={Listbox}
          renderInput={(params): JSX.Element => (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                readOnly,
                placeholder,
                error: isError,
                endAdornment: readOnly ? null : (
                  <>
                    {pending && <CircularProgress size={20} color="inherit" />}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
          readOnly={readOnly}
          disabled={disabled}
          loadingText={t('Loading')}
          noOptionsText={t('DataGrid.noResultsOverlayLabel')}
        />

        {!readOnly && error && <FormHelperText error>{error}</FormHelperText>}
      </Styled.CustomFormControl>
    );
  }
);
