import {
  FormControl as FormControlMui,
  styled,
  Autocomplete as AutocompleteMui,
} from '@mui/material';

export const FormControl = styled(FormControlMui)(() => ({
  '& .Mui-readOnly': {
    paddingLeft: '0 !important',
  },
}));

export const Autocomplete = styled(AutocompleteMui)<{ readOnly: boolean }>(
  ({ readOnly }) => ({
    maxHeight: readOnly ? '250px' : 'auto',
    overflow: 'auto',
  })
);

export const AllBuildings = styled('div')`
  margin-top: 6px;
`;
