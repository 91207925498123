import styled from '@emotion/styled';

export const StyledNewsCard = styled.div`
  padding: 24px;

  ul {
    list-style-type: initial;
  };

  figure {
    margin: 1rem auto;
  };

  table {
    width: 100%;
  };

  table, th, td {
    border: 1px solid #9e9e9e;
    border-collapse: collapse;
  };

  img {
    max-width: 100%;
    object-fit: contain;
    height: auto;
  };
`;
