import { createStore, createEvent } from "effector";
import type { NewsFeedResponse } from '../news-feed/news-feed.types';

/* флаг означающий что после первого запроса не вызываем поповер */
export const $isMounted = createStore<boolean>(false);
export const $subscribedIds = createStore<number[]>([]);

export const inspectIds = createEvent<NewsFeedResponse>();
export const observeData = createEvent<NewsFeedResponse>();
export const sendPreviewNews = createEvent<NewsFeedResponse>();
export const subscribeIds = createEvent<number[]>();
