import { createEffect, sample } from 'effector';
import { createGate } from 'effector-react';
import { cache, createQuery, localStorageCache } from '@farfetched/core';
import { signout } from '@features/common';
import { apiRequests } from './api';
import { DataResponse } from './types';

export const ControlGate = createGate();

const fxGetData = createEffect<void, DataResponse, Error>().use(apiRequests.getData);

export const dataQuery = createQuery({
  name: 'dataTimezones',
  effect: fxGetData,
  mapData: ({ result }) =>
    result.timezones.map(({ offset, timezone }) => ({
      id: timezone,
      label: `${timezone} (${offset})`,
    })),
});

cache(dataQuery, {
  adapter: localStorageCache({ maxAge: '5m' }),
  purge: signout,
});

sample({
  clock: ControlGate.open,
  target: dataQuery.start,
});
