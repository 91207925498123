import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import PdfIcon from '@img/pdf_icon.svg';

export const GetFileIcon = ({ extention }: { extention?: string }) => {
  switch (extention) {
    case 'pdf':
      return <PdfIcon />;

    default:
      return <InsertDriveFileIcon />;
  }
};
