import { useEffect, useState, useMemo, RefObject } from 'react';
import type { positionValues } from 'react-custom-scrollbars';

type Props = {
  circularProgressRef: RefObject<HTMLElement | null>;
  listRef: RefObject<HTMLElement | null>;
  isAllowNextPage: boolean;
  isFetchByParams: boolean;
};

type InfinityScrollResponse = {
  onScrollStop: () => void;
  onScrollFrame: (values: positionValues) => void;
  isCircularProgress: boolean;
}

let pointIrretrievable: number = 0;
let sourceCircumference: number = 0;
let prevCurrentHeight: number = 0;
let timeout: ReturnType<typeof setTimeout>;

export const useInfinityScroll = ({
  listRef,
  isAllowNextPage,
  isFetchByParams,
  circularProgressRef
}: Props): InfinityScrollResponse => {
  const [isCircularProgress, setCircularProgress] = useState<boolean>(false);

  const isBlocked = useMemo(() => isCircularProgress || !isAllowNextPage, [isCircularProgress, isAllowNextPage]);

  const incrementCircular = (circleEl: SVGCircleElement | null | undefined) => {
    if (circleEl) {
      pointIrretrievable += 25;
      circleEl.style.strokeDashoffset = sourceCircumference - pointIrretrievable + 'px';
    }
  };

  const decrementCircular = (circleEl: SVGCircleElement | null | undefined) => {
    if (circleEl) {
      pointIrretrievable -= 25;
      circleEl.style.strokeDashoffset = sourceCircumference + pointIrretrievable + 'px';
    }
  };

  const onScrollFrame = (values: positionValues) => {
    if (isBlocked) return;
    clearTimeout(timeout);

    const currentHeight = values.scrollTop + values.clientHeight + values.top + 48;
    const circleEl = circularProgressRef.current?.querySelector('circle');

    if (currentHeight >= values.scrollHeight) {
      setCircularProgress(true);
      /* TODO ДОРАБОТАТЬ */
      return;
      // prevCurrentHeight = currentHeight;
      // if (listRef.current && pointIrretrievable >= 48) {
      //   setCircularProgress(true);
      //   listRef.current.style.height = listRef.current?.getBoundingClientRect().height - 25 + 'px'
      //   return;
      // } else if (listRef.current) {
      //   incrementCircular(circleEl);
      //   listRef.current.style.height = listRef.current?.getBoundingClientRect().height + 10 + 'px'
      // }
    } else if (prevCurrentHeight > currentHeight && pointIrretrievable > 0) {
      // decrementCircular(circleEl);
    }
  };

  const onScrollStop = () => {
    if (isBlocked) return;
    if (pointIrretrievable > 0) {
      /* Если перестали скролить когда уже пояаился лоадер, то по таймауту сбрасываю состояние */
      timeout = setTimeout(() => {
        if (listRef.current && listRef.current.parentNode) {
          const scrollHeight = (listRef.current.parentNode as HTMLElement).scrollHeight ?? 0;
          const clientHeight = (listRef.current.parentNode as HTMLElement).clientHeight ?? 0;
          (listRef.current.parentNode as HTMLElement).scrollTo({
            top: scrollHeight - clientHeight - pointIrretrievable - 72
          });
          listRef.current.style.height = `${scrollHeight - pointIrretrievable - 72}px`;
        }
        pointIrretrievable = 0;
      }, 1000);
    }
  }

  useEffect(() => {
    const circleEl = circularProgressRef.current?.querySelector('circle');
    if (circleEl) {
      const radiusCircle = circleEl.getAttribute('r');
      const circumference = 2 * 3.14 * Number(radiusCircle);
      sourceCircumference = circumference;
    }
    return () => {
      pointIrretrievable = 0;
      sourceCircumference = 0;
    }
  }, []);

  useEffect(() => {
    if (isFetchByParams) {
      setCircularProgress(false);
    }
  }, [isFetchByParams]);

  return {
    onScrollStop,
    onScrollFrame,
    isCircularProgress 
  }
};
