import { useEffect, useState } from 'react';
import { Provider, useUnit } from 'effector-react';
import { FormHelperText, FormLabel, OutlinedInputProps } from '@mui/material';
import CropOriginalIcon from '@mui/icons-material/CropOriginal';
import { withControl } from '@features/effector-form/hoc/withControl';
import {
  CustomControlProps,
  GeneralControlProps,
} from '@features/effector-form/types/control';
import { LoaderHover } from './components/_loaderHover';
import { ErrorMessage } from './components/error';
import { ImageEditor } from './components/imageEritor';
import { dropzoneInit } from './helpers/dropzoneInit';
import { handleUploadFile } from './helpers/uploadFile';
import { $isLoading, $uploadedFile, ControlGate, createScope } from './model';
import * as Styled from './styled';
import { AdditionalProps } from './types';

export const UploadDropzoneSingleFileControlScoped = ({
  label,
  value,
  setValue,
  error,
  required,
  readOnly,
  disabled,
  fileTypes,
  width,
  height,
  helperText,
  editor,
  id,
}: GeneralControlProps & CustomControlProps<OutlinedInputProps & AdditionalProps>) => {
  const hideDropzone = disabled || readOnly || value;
  const [hover, setHover] = useState(false);
  const [uploadedFile, isLoading] = useUnit([$uploadedFile, $isLoading]);

  useEffect(() => {
    dropzoneInit(id, fileTypes, editor);
  }, []);

  useEffect(() => {
    if (!uploadedFile) return;

    setValue(uploadedFile.url);
  }, [uploadedFile]);

  const isVisibleHover = !disabled && !isLoading && !readOnly && hover;

  const loaderHover = (
    <LoaderHover visible={isVisibleHover} value={value} id={id} setValue={setValue} />
  );

  const showError = !readOnly && !!error;

  return (
    <>
      <ControlGate />

      <ErrorMessage id={id} />

      <ImageEditor id={id} />

      <Styled.CustomFormControl error={showError}>
        <FormLabel required={!readOnly && required}>{label}</FormLabel>

        <Styled.Form
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          style={{ width, height }}
        >
          <Styled.Dropzone
            id={`dataupload_${id}`}
            htmlFor={`file-input_${id}`}
            style={{ display: hideDropzone ? 'none' : 'inline-block' }}
          >
            <Styled.EmptyDropzone error={showError ? 1 : 0}>
              {loaderHover}

              <CropOriginalIcon
                fontSize="medium"
                color={showError ? 'error' : 'primary'}
              />
            </Styled.EmptyDropzone>
          </Styled.Dropzone>

          {hideDropzone && (
            <>
              {value && (
                <Styled.Dropzone htmlFor={`file-input_${id}`}>
                  {loaderHover}

                  <Styled.CustomAvatar variant="rounded" src={value} />
                </Styled.Dropzone>
              )}

              {!value && (
                <Styled.EmptyDropzone
                  notActive={!readOnly && disabled ? 1 : 0}
                  error={showError ? 1 : 0}
                >
                  {loaderHover}

                  <CropOriginalIcon
                    fontSize="medium"
                    color={showError ? 'error' : 'primary'}
                  />
                </Styled.EmptyDropzone>
              )}
            </>
          )}

          {!disabled && !readOnly && (
            <input
              type="file"
              onChange={(event) =>
                handleUploadFile(id, event.target.files, fileTypes, editor)
              }
              style={{ display: 'none' }}
              id={`file-input_${id}`}
              onClick={(event) => {
                (event.target as HTMLInputElement).value = '';
              }}
            />
          )}
        </Styled.Form>

        {helperText && !readOnly && <FormHelperText>{helperText}</FormHelperText>}

        {error && <FormHelperText error>{error}</FormHelperText>}
      </Styled.CustomFormControl>
    </>
  );
};

export const UploadDropzoneSingleFileControl = withControl<
  OutlinedInputProps & AdditionalProps
>((props) => {
  const scope = createScope(props.id);

  return (
    <Provider value={scope}>
      <UploadDropzoneSingleFileControlScoped {...props} />
    </Provider>
  );
});
