import { Scope, combine, createDomain, fork, merge, sample } from 'effector';
import { createGate } from 'effector-react';
import { signout } from '@features/common';
import { apiRequests } from '../api';
import { UploadedFile, UploadFilePayload, UploadFileResponse } from '../types';

export const ControlDomain = createDomain();
export const ControlGate = createGate();

export const fxGetData = ControlDomain.createEffect<
  UploadFilePayload,
  UploadFileResponse,
  Error
>().use(apiRequests.uploadFile);

export const $uploadedFile = ControlDomain.createStore<UploadedFile | null>(null)
  .on(fxGetData.doneData, (_, { file }) => file)
  .reset(ControlGate.close, signout);

export const startUploadFile = ControlDomain.createEvent<File>();

/** Отрпавляю файл на загрузку */
sample({
  clock: startUploadFile,
  fn: (file) => ({ file }),
  target: fxGetData,
});

export const $isLoading = combine(
  fxGetData.pending,
  (fxGetDataPending) => fxGetDataPending
);

/** Передавать все эффекты раздела */
export const errorOccured = merge([fxGetData.fail]);
export const manualError = ControlDomain.createEvent<Error>();

export const clearError = ControlDomain.createEvent();

export const $error = ControlDomain.createStore<Error | null>(null)
  .on(errorOccured, (_, { error }) => error)
  .on(manualError, (_, error) => error)
  .reset([ControlGate.close, signout, clearError]);

export const scopes: { [key: string]: Scope } = {};
export const createScope = (name: string) => {
  if (scopes[name]) return scopes[name];

  scopes[name] = fork(ControlDomain);

  return scopes[name];
};
