import { useState } from 'react';
import { applyMiddleware, createStore } from 'redux';
import { Provider } from 'react-redux';
import thunkMiddleware from 'redux-thunk';
import { Route, Router, Switch } from 'react-router-dom';
import { YMInitializer } from 'react-yandex-metrika';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { StyledEngineProvider } from '@mui/material/styles';
import { ErrorBoundary } from '@features/error';
import { AppGate, history } from '@features/common';
import { NewsFeed } from '@pages/news-feed';
import { theme } from '../configs/theme';
import rootReducer from '../redux/reducers/_root';
import { enviroment } from '../consts/common';
import { AuthorizationPage } from '../features/authorization';
import { DashboardFullscreenPage } from '../features/dashboard-fullscreen';
import PageLayout from './templates/PageTemplate';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import ru from 'date-fns/locale/ru';

const middlewares = applyMiddleware(thunkMiddleware);

const store = createStore(rootReducer, middlewares);

export default function App() {
  useState(() => {
    const lang = localStorage.getItem('lang');

    if (!lang) {
      const defaultLang = process.env.LANG;

      localStorage.setItem('lang', defaultLang);
      window.location.reload();
    }
  }, []);

  return (
    <>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ru}>
            <CssBaseline />
            {enviroment !== 'development' && (
              <YMInitializer
                accounts={[57345664]}
                options={{ webvisor: true, clickmap: true, trackLinks: true }}
                version="2"
              />
            )}
            <ErrorBoundary>
              <AppGate />
              <NewsFeed />
              <Provider store={store}>
                <Router history={history}>
                  <Switch>
                    <Route exact path="/auth" component={AuthorizationPage} />
                    <Route
                      exact
                      path="/dashboard-fullscreen"
                      component={DashboardFullscreenPage}
                    />
                    <Route path="/" component={PageLayout} />
                  </Switch>
                </Router>
              </Provider>
            </ErrorBoundary>
          </LocalizationProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </>
  );
}
