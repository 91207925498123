import { FormControl, styled } from '@mui/material';

export const CustomFormControl = styled(FormControl)(() => ({
  display: 'flex',
  flexDirection: 'column',
  '& .MuiInputBase-readOnly': {
    background: 'transparent',
    paddingLeft: 0,
  },
}));
