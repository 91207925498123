import { ruRU } from '@mui/material/locale';
import { createTheme } from '@mui/material/styles';
import { ruRU as dataGridRU } from '@mui/x-data-grid-pro';
import { ruRU as datePickersRU } from '@mui/x-date-pickers-pro';
import { breakpoints } from '@shared/theme/breakpoints';
import { components } from '@shared/theme/components';
import { mixins } from '@shared/theme/mixins';
import { palette } from '@shared/theme/palette';
import { shadows } from '@shared/theme/shadows';
import { shape } from '@shared/theme/shape';
import { transitions } from '@shared/theme/transitions';
import { typography } from '@shared/theme/typography';
import { zIndex } from '@shared/theme/zIndex';

export const theme = createTheme(
  {
    direction: 'ltr',
    breakpoints,
    components,
    palette,
    shape,
    typography,
    mixins,
    shadows,
    transitions,
    zIndex,
  },
  ruRU,
  dataGridRU,
  datePickersRU
);

console.log('theme:', theme);
