import { allSettled } from 'effector';
import { DropzoneFile } from 'dropzone';
import i18n from '@shared/config/i18n';
import { setEditorConfig } from '../model/editor.model';
import { createScope, manualError, startUploadFile } from '../model/upload.model';
import { Editor } from '../types';

const { t } = i18n;

const fileTypeGuard = async (
  id: string,
  file: File | DropzoneFile,
  fileTypes?: string[]
) => {
  if (!fileTypes?.length) return true;

  const fileNameArr = (file.name ?? (file as any).upload.name).split('.');
  const fileExtension = fileNameArr[fileNameArr.length - 1];

  const isValid = fileTypes.includes(fileExtension);

  if (!isValid) {
    const scope = createScope(id);

    await allSettled(manualError, {
      scope,
      params: new Error(t('FileNotLoadedCheckType') as string),
    });
  }

  return isValid;
};

export const handleUploadFile = async (
  id: string,
  files: File[] | FileList | null,
  fileTypes?: string[],
  editor?: Editor
) => {
  if (!files) return;

  const file = files[files.length - 1];

  const isValidFileType = await fileTypeGuard(id, file, fileTypes);
  if (!isValidFileType) return;

  const scope = createScope(id);

  if (editor?.active) {
    await allSettled(setEditorConfig, { scope, params: { ...editor, file } });
    return;
  }

  await allSettled(startUploadFile, { scope, params: file });
};
