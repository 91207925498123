import { useEffect } from 'react';
import { useGate, useUnit } from 'effector-react';
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  CircularProgress,
  FormControl,
  FormHelperText,
  FormLabel,
  OutlinedInput,
  OutlinedInputProps,
} from '@mui/material';
import TextField from '@mui/material/TextField';
import { Listbox } from '@features/effector-form/components/listbox';
import { withControl } from '@features/effector-form/hoc/withControl';
import {
  CustomControlProps,
  GeneralControlProps,
} from '@features/effector-form/types/control';
import { ControlGate, dataQuery, startRequest } from './model';
import * as Styled from './styled';
import { Option } from './types';

type AdditionalProps = {
  id: string;
  clearIcon?: JSX.Element | boolean;
};

export const StoryFrameButtonTypeControl = withControl<
  OutlinedInputProps & AdditionalProps
>(
  ({
    id,
    label,
    value,
    setValue,
    error,
    required,
    readOnly,
    disabled,
    multiple,
    clearIcon,
  }: GeneralControlProps & CustomControlProps<OutlinedInputProps & AdditionalProps>) => {
    useGate(ControlGate);
    const { data, pending } = useUnit(dataQuery);

    const handleChange = (event: any, newValue: any) => {
      if (multiple) {
        setValue(newValue.map((item: Option) => item.id) || null);
      } else {
        setValue(newValue?.id || null);
      }
    };

    const getValue = () => {
      if (value === null) return value;

      if (!data && multiple) return [];
      if (!data && !multiple) return null;

      return multiple
        ? data?.filter((item) => value?.indexOf(item.id) > -1)
        : data?.find((item) => item.id === value);
    };

    const resolveEndAdornment = (params: AutocompleteRenderInputParams) => {
      if (readOnly) return null;

      if (pending) return <CircularProgress size={20} color="inherit" />;

      return params.InputProps.endAdornment;
    };

    useEffect(() => {
      if (!!data?.length) return;

      startRequest();
    }, []);

    const values = getValue();

    return (
      <Styled.CustomFormControl>
        <FormLabel required={required}>{label}</FormLabel>

        {!readOnly && (
          <Autocomplete
            loading={pending}
            value={values}
            options={!data ? [] : data}
            onChange={handleChange}
            ListboxComponent={Listbox}
            renderInput={(params) => (
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                  required,
                  readOnly,
                  disabled,
                  endAdornment: resolveEndAdornment(params),
                }}
              />
            )}
            readOnly={readOnly}
            disabled={disabled}
            multiple={multiple}
            clearIcon={clearIcon}
            size="small"
          />
        )}

        {readOnly && (
          <OutlinedInput
            value={values?.label ?? '-'}
            readOnly={readOnly}
            disabled={disabled}
          />
        )}

        <FormHelperText error>{error}</FormHelperText>
      </Styled.CustomFormControl>
    );
  }
);
