import { Backdrop, styled, Typography as TypographyMui } from '@mui/material';
import {
  AddPhotoAlternateOutlined as AddPhotoAlternateOutlinedIconMui,
  DeleteOutline as DeleteOutlineMui,
} from '@mui/icons-material';

export const CustomBackdrop = styled(Backdrop)(() => ({
  background: '#000000de',
  display: 'flex',
  flexDirection: 'column',
  '& > *': {
    flex: '1 1 auto',
    width: '100%',
  },
}));

export const Typography = styled(TypographyMui)`
  color: rgba(255, 255, 255, 1);
`;

export const DeleteOutlineIcon = styled(DeleteOutlineMui)(() => ({
  color: 'rgba(255, 255, 255, 1)',
}));

export const AddPhotoAlternateOutlinedIcon = styled(AddPhotoAlternateOutlinedIconMui)(
  () => ({
    color: 'rgba(255, 255, 255, 1)',
  })
);

export const HoverItem = styled('label')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
  justifyContent: 'center',
  alignItems: 'center',
  opacity: 0.6,
  cursor: 'pointer',

  '&:hover': {
    opacity: 1,
  },
}));
