import { useEffect } from 'react';
import { useGate, useUnit } from 'effector-react';
import {
  Autocomplete,
  FormControl,
  FormHelperText,
  FormLabel,
  TextField,
  CircularProgress,
} from '@mui/material';
import { Listbox } from '@features/effector-form/components/listbox';
import { BuildingControlProps } from '@features/effector-form/controls/building-controls/types';
import { withControl } from '@features/effector-form/hoc/withControl';
import { BuildingControlGate, buildingQuery } from '../model';

export const BuildingControl = withControl<BuildingControlProps>(
  ({
    value,
    setValue,
    error,
    label,
    required,
    readOnly,
    disabled,
    complex,
    complexes,
    isError,
  }) => {
    useGate(BuildingControlGate, { complex });

    const { data, pending } = useUnit(buildingQuery);

    const getOptions = () => {
      if (!data) return [];

      if (!complexes?.length && !complex) return data;

      const joinComplexes = complexes ?? [];
      if (complex) joinComplexes.push(complex);

      return data.filter(({ complexId }) => joinComplexes.includes(complexId));
    };

    const getValue = () => {
      return options.find(({ id }) => value === id) ?? null;
    };

    const options = getOptions();
    const values = getValue();

    const stopPending = !pending;

    useEffect(() => {
      if (readOnly) return;

      setValue(values?.id ?? null);
    }, [stopPending, readOnly]);

    return (
      <FormControl required={required} disabled={disabled}>
        {label && <FormLabel error={isError}>{label}</FormLabel>}
        <Autocomplete
          loading={pending}
          value={values}
          options={options}
          onChange={(event: any, newValue: any) => {
            setValue(newValue?.id || null);
          }}
          ListboxComponent={Listbox}
          renderInput={(params): JSX.Element => (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                readOnly,
                error: isError,
                endAdornment: readOnly ? null : (
                  <>
                    {pending && <CircularProgress size={20} color="inherit" />}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
          readOnly={readOnly}
          disabled={disabled}
        />
        {error && <FormHelperText error>{error}</FormHelperText>}
      </FormControl>
    );
  }
);
