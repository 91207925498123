import { createGate } from "effector-react";
import { createStore, createEvent, createEffect } from "effector";
import { apiNewsFeed } from '../../api';
import { DEFAULT_PARAMS } from './news-feed.constants';
import { Mode } from './news-feed.types';

import {
  News,
  Lang,
  Params,
  NewsFeedPayload,
  NewsFeedResponse,
  NewsReadPayload,
  NewsReadResponse,
  NewsReadResponseEffect
} from './news-feed.types';

export const PageGate = createGate();

/* Несколько одинаковых эффектов получение записей, так как может быть несколько кейсов: 
  1. подписка за новыми записями по интервалу, новые записи надо пушить в начало списка
  2. инфинити скролл, пушить записи в конец списка
  3. поиск заменить все записи в сторе $news
*/
export const fxGetNewsListByInterval = createEffect<NewsFeedPayload, NewsFeedResponse, Error>()
  .use(apiNewsFeed.getNewsList);
export const fxGetNewsListByParams = createEffect<NewsFeedPayload, NewsFeedResponse, Error>()
  .use(apiNewsFeed.getNewsList);

export const fxGetNewsRead = createEffect<NewsReadPayload, NewsReadResponse, Error>()
  .use(apiNewsFeed.postNewsRead);

export const getNewsListByInterval = createEvent<any>();
export const setNewsFeed = createEvent<NewsFeedResponse>();
export const pushNewsFeed = createEvent<NewsFeedResponse>();
export const sendUnshiftNewsFeed = createEvent<NewsFeedResponse>();
export const unshiftNewsFeed = createEvent<NewsFeedResponse>();
export const updateReadStatusById = createEvent<NewsReadResponseEffect>();

export const getFxGetNewsList = createEvent<NewsFeedPayload>();
export const getNextPage = createEvent();
export const toggleNewsFeed = createEvent<boolean>();
export const openNewsCard = createEvent<number>();
export const setMode = createEvent<Mode>();
export const setNewRows = createEvent<News[]>();
export const setIsAllowNextPage = createEvent<boolean>();
export const resetParams = createEvent();

export const $mode = createStore<Mode>(Mode.NEWSLIST);
export const $news = createStore<News[]>([]);
export const $total = createStore<number>(0);
export const $isLoading = createStore<boolean>(false);
export const $selectedNews = createStore<News | null>(null);
export const $isOpenNewsFeed = createStore<boolean>(false);
export const $isAllowNextPage = createStore<boolean>(true);
export const $isFetchByParams = createStore<boolean>(false);
export const $params = createStore<Params>({
  ...DEFAULT_PARAMS,
  search: '',
  lang: localStorage.getItem('lang') as Lang ?? 'ru'
});
