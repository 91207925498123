import { useUnit } from 'effector-react';
import { $isLoading } from '../../model/upload.model';
import * as Styled from './styled';

export const Loader = () => {
  const [isLoading] = useUnit([$isLoading]);

  return (
    <Styled.CustomBackdrop open={isLoading}>
      <Styled.CustomCircularProgress />
    </Styled.CustomBackdrop>
  );
};
