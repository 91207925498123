import { useEffect, useMemo } from 'react';
import { useUnit } from 'effector-react';
import { List, ListItem, ListItemButton, Avatar } from '@mui/material';
import StickyNote2OutlinedIcon from '@mui/icons-material/StickyNote2Outlined';
import { togglePopover, onClickPreview, $isOpenPopover, $previewNews } from '../../model';

import { ListSubheader } from '../../molecules';

import { StyledPopover, StyledListItemAvatar, StyledListItemText, StyledPaper } from './styled';

type Props = {
  anchorEl: HTMLButtonElement | null;
};

export const NewsPreview = ({ anchorEl }: Props) => {
  const [isOpenPopover, previewNews] = useUnit([$isOpenPopover, $previewNews]);

  const onClosePopover = () => {
    togglePopover(false);
  };

  const onClickItem = (id: number) => () => {
    onClickPreview(id);
  };

  const handleUnForcedView = () => {
    togglePopover(false);
  };

  const isForcedView = useMemo(() => {
    return previewNews
      .reduce((acc_forced_view, { forced_view }) => acc_forced_view || forced_view, false);
  }, [previewNews]);

  useEffect(() => {
    if (isOpenPopover && !isForcedView) {
      document.body.addEventListener('click', handleUnForcedView);
    } else {
      document.body.removeEventListener('click', handleUnForcedView);
    }
    return () => {
      document.body.removeEventListener('click', handleUnForcedView);
    }
  }, [isOpenPopover, isForcedView]);

  return (
    <StyledPopover
      id="news-feed-badge-popover"
      placement="bottom-end"
      open={isOpenPopover}
      anchorEl={anchorEl}
      // onClose={onClosePopover}
      >
        <StyledPaper>
          <List
            dense
            subheader={<ListSubheader onClose={onClosePopover} />}
          >
            {previewNews.map((item) => (
              <ListItem disablePadding key={item.id} onClick={onClickItem(item.id)}>
                <ListItemButton>
                <StyledListItemAvatar>
                  {item.cover_image_url
                    ? <Avatar alt="preview" src={item.cover_image_url} variant="rounded" />
                    : <Avatar alt="preview" variant="rounded"><StickyNote2OutlinedIcon /></Avatar>}
                </StyledListItemAvatar>
                  <StyledListItemText primary={item.title} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </StyledPaper>
    </StyledPopover>
  )
}