
import { useState, useRef } from 'react';
import { useUnit } from 'effector-react';
import Divider from '@mui/material/Divider';
import { CustomScrollbar } from '@ui/index';
import MarkdownToJSX from 'markdown-to-jsx';
import type Scrollbars from 'react-custom-scrollbars';
import type { positionValues } from 'react-custom-scrollbars';

import { $selectedNews, setMode, Mode } from '../../models';
import { ToolbarCard } from '../../molecules';

import { StyledNewsCard } from './styled'; 

type Props = {
  onClose: () => void;
};

export const NewsCard = ({ onClose }: Props) => {
  const [selectedNews] = useUnit([$selectedNews]);
  const scrollbarRef = useRef<Scrollbars>(null);
  const [isShowDivider, setIsShowDivider] = useState<boolean>(false);

  const onBack = () => {
    setMode(Mode.NEWSLIST);
  };

  const onScrollFrame = ({ scrollTop }: positionValues) => {
    const isScrolled = scrollTop > 0;
    if (isScrolled !== isShowDivider) {
      setIsShowDivider(isScrolled);
    }
  };

  return (
    <>
      <ToolbarCard
        title={selectedNews?.title ?? ''}
        onBack={onBack}
        onClose={onClose}
      />
      {isShowDivider && <Divider />}
      <CustomScrollbar ref={scrollbarRef} onScrollFrame={onScrollFrame}>
        <StyledNewsCard>
          <MarkdownToJSX>
            {selectedNews?.html_markup ?? ''}
          </MarkdownToJSX>
        </StyledNewsCard>
      </CustomScrollbar>
    </>
  )
};
