export type Lang = 'ru' | 'en';

export type News = {
  id: number;
  title: string;
  preview: string;
  is_read: boolean;
  created_at: string;
  html_markup: string;
  forced_view: boolean;
  cover_image_url: string;
};

export type Params = {
  lang: Lang;
  page?: number;
  per_page?: number;
  search?: string;
};

export type Meta = {
  last_page: number;
  page: number;
  per_page: number;
  total: number;
};

export type NewsFeedPayload = {
  lang: Lang;
  page?: number;
  per_page?: number;
  search?: string;
};

export type NewsFeedResponse = {
  news: News[];
  meta: Meta;
}

export type NewsReadPayload = {
  news: number[];
}

export type NewsReadResponse = {
  news: number[];
  meta: Meta;
}

export type NewsReadResponseEffect = {
  params: NewsReadPayload,
  result: {
    news: number[];
    meta: Meta;
  }
}

// = 'news-list' | 'news-card';
export enum Mode {
  NEWSLIST,
  NEWSCARD
}