import { styled } from '@mui/material/styles';
import { DateTimePicker as DateTimePickerMui } from '@mui/x-date-pickers-pro';

export const DateTimePicker = styled(DateTimePickerMui)(() => ({
  '& .MuiIconButton-edgeEnd': {
    marginRight: '-7px',
  },
  '& input': {
    textTransform: 'lowercase',
  },
  '& input::placeholder': {
    textTransform: 'lowercase',
  },
}));
