import { createGate } from 'effector-react';
import { signout } from '@features/common';
import { Editor } from '../types';
import { ControlDomain } from './upload.model';

export const ControlEritorGate = createGate();

export const setEditorConfig = ControlDomain.createEvent<Editor>();
export const resetEditorConfig = ControlDomain.createEvent();
export const $editorConfig = ControlDomain.createStore<Editor | null>(null)
  .on(setEditorConfig, (_, file) => file)
  .reset(resetEditorConfig, ControlEritorGate.close, signout);
