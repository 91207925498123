import styled from '@emotion/styled';
import { ListSubheader, Typography } from '@mui/material';

export const StyledTypography = styled(Typography)(() => ({
  display: 'flex',
  alignItems: 'center'
}));

export const StyledListSubheader = styled(ListSubheader)(({ theme }: any) => ({
  display: 'flex',
  color: 'white',
  justifyContent: 'space-between',
  padding: '8px 8px 8px 16px',
  backgroundColor: 'transparent'
}))