import { sample } from 'effector';
import { showPreviewNews } from '@features/news-feed-badge';
import {
  fxGetNewsListByInterval,
  fxGetNewsListByParams,
  sendUnshiftNewsFeed,
  $isOpenNewsFeed,
  type News,
} from '../news-feed';
import {
  inspectIds,
  observeData,
  subscribeIds,
  $subscribedIds,
  sendPreviewNews,
} from './news-subscriber.model';
import { identifyNewIds } from '../../libs';
import type { NewsFeedResponse } from '../news-feed/news-feed.types';

/* подписываюсь на каждое обновление запроса */
fxGetNewsListByInterval.done.watch(({ result }) => inspectIds(result));
fxGetNewsListByParams.done.watch(({ result: { news } }) => subscribeIds(takeID(news)));

$subscribedIds
  .on(subscribeIds, (subscribedIds, newds) => subscribedIds.concat(newds));

/* делаю выборку из новых айдишников */
sample({
  clock: inspectIds,
  source: $subscribedIds,
  fn: identifyNewIds,
  target: observeData
});

/* делаю проверку есть ли новые айдишники */
sample({
  clock: observeData,
  filter: (response) => Boolean(response.news.length > 0),
  target: [
    sendPreviewNews,
    sendUnshiftNewsFeed,
    subscribeIds.prepend(({ news }: NewsFeedResponse) => takeID(news))
  ]
});

/* Если дровер закрыт, то отправляю в поповер новые записи */
sample({
  clock: sendPreviewNews,
  source: $isOpenNewsFeed,
  filter: (isOpenNewsFeed) => !isOpenNewsFeed,
  fn: (_, { news }) => news.slice(0, 3),
  target: showPreviewNews
});

function takeID(news: News[]): number[] {
  return news.map((item) => item.id);
}

// setTimeout(() => {
//   showPreviewNews([
//     {
//       id: 42342,
//       title: 'Настройка автоматического назначения исполнителя на заявки',
//       cover_image_url: 'https://git.ujin.tech/assets/img/logo.svg',
//       forced_view: false
//     },
//     {
//       id: 42341,
//       title: 'Настройка автоматического назначения исполнителя на заявки. Настройка автоматического назначения исполнителя на заявки',
//       cover_image_url: '',
//       forced_view: false
//     },
//     {
//       id: 42340,
//       title: 'Настройка автоматического назначения исполнителя на заявки. Настройка автоматического назначения исполнителя на заявки',
//       cover_image_url: '',
//       forced_view: false
//     }
//   ])
// }, 5000);

