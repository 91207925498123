import { sample, createEffect } from 'effector';
import { $isAuthenticated } from '@features/common';
import { PageGate, getNewsListByInterval, setNewsFeed, $params } from './news-feed.model';
import { subscribeIds } from '../news-subscriber/news-subscriber.model';
import { apiNewsFeed } from '../../api';

import type { NewsFeedPayload, NewsFeedResponse, News } from './news-feed.types';

/* при маунте ленты новостей запрашиваю отдельным эффектом новости
  что бы после его завершения начать запрашивать новости через интервал
  и не было лишних проверок на новые записи

  Лента новостей монтируется отдельно от роутинга, поэтому нужен флаг $isAuthenticated
*/
const fxGetNewsListByMount = createEffect<NewsFeedPayload, NewsFeedResponse, Error>()
  .use(apiNewsFeed.getNewsList);

sample({
  clock: [PageGate.open, $isAuthenticated],
  source: $params,
  target: fxGetNewsListByMount
});

sample({
  clock: fxGetNewsListByMount.done,
  fn: ({ result }) => result,
  target: [
    setNewsFeed,
    getNewsListByInterval,
    subscribeIds.prepend(takeID)
  ]
});

function takeID({ news }: { news: News[] }): number[] {
  return news.map((item) => item.id);
}
