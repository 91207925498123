import styled from '@emotion/styled';
import Typography from '@mui/material/Typography'; 

export const StyledToolbar = styled('div')`
  padding: 24px;
`;

export const StyledToolbarHeader = styled('div')`
  display: flex;
  gap: 24px;
  justify-content: space-between;
  align-items: flex-start;
`;


export const StyledTypography = styled(Typography)`
  padding-bottom: 24px;
  flex: 1;
`;

export const StyledSearchForm = styled('div')`
  & > div {
    width: 100%;
  }
`;
