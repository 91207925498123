import { sample } from 'effector';
import { throttle } from 'patronum/throttle';
import { $isOpenNewsFeed } from '../news-feed/news-feed.model';
import { searchForm, searchChanged, resetSearch } from './search-form.model';

throttle({
  source: searchForm.values,
  timeout: 1000,
  target: searchChanged,
});

/* если поле пустое, то сбрасываю форму, для того, что бы среагировать юнит reset */
sample({
  clock: searchChanged,
  filter: ({ search }) => search.length === 0,
  target: resetSearch
});

/* если дровер закрыт, сбрасываю состояние поиска */

// sample({
//   clock: $isOpenNewsFeed,
//   filter: (isOpenNewsFeed) => !isOpenNewsFeed,
//   target: searchForm.reset
// });
