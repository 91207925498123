import { useState, createRef, useEffect, forwardRef, useImperativeHandle } from 'react';
import { ReactCropperElement } from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import * as Styled from './styled';

const minCropBoxRatio = 0.7;

export const ImageCropper = forwardRef(
  (
    {
      file,
      fixedCropBox,
      width,
      height,
      cropWidth,
      cropHeight,
      aspectRatio,
      initialAspectRatio,
      zoomTo,
      uploadFile,
      fitZoom,
    }: {
      file: File;
      fixedCropBox: boolean;
      width: string;
      height: string;
      cropWidth: number;
      cropHeight: number;
      aspectRatio: number;
      initialAspectRatio: number;
      zoomTo: number;
      uploadFile: (file: File) => Promise<void>;
      fitZoom?: boolean;
    },
    ref
  ) => {
    const [image, setImage] = useState();
    const cropperRef = createRef<ReactCropperElement>();

    useImperativeHandle(ref, () => ({
      startFileUpload() {
        cropImage();
      },
    }));

    const cropImage = () => {
      if (!cropperRef.current?.cropper) return;

      const cropedFileDataURL = cropperRef.current?.cropper
        .getCroppedCanvas()
        .toDataURL();

      fetch(cropedFileDataURL)
        .then((result) => result.blob())
        .then((blob) => {
          const cropedFile = new File([blob], `image.jpeg`, { type: 'image/jpeg' });
          console.log('canvasFile to upload', cropedFile);

          uploadFile(cropedFile);
        });
    };

    const extractImage = () => {
      const reader = new FileReader();

      reader.onload = () => {
        setImage(reader.result as any);
      };
      reader.readAsDataURL(file);
    };

    const fitImageToCropBox = () => {
      if (!fitZoom) return;

      const cropper = cropperRef?.current?.cropper;
      if (!cropper) return;

      const canvasData = cropper.getCanvasData();
      const cropBoxData = cropper.getCropBoxData();

      const widthCoe = cropBoxData.height / canvasData.height;
      cropper.zoomTo(zoomTo * widthCoe);
    };

    useEffect(() => {
      extractImage();
    }, []);

    const CropperComponent = fixedCropBox ? Styled.FixedCropper : Styled.NotFixedCropper;

    return (
      <CropperComponent
        ref={cropperRef}
        dragMode="move"
        style={{ height, width }}
        zoomTo={zoomTo}
        initialAspectRatio={initialAspectRatio}
        aspectRatio={aspectRatio}
        src={image}
        viewMode={0}
        minCropBoxWidth={fixedCropBox ? cropWidth : cropWidth * minCropBoxRatio}
        minCropBoxHeight={fixedCropBox ? cropHeight : cropHeight * minCropBoxRatio}
        background={false}
        responsive={true}
        checkOrientation={false}
        guides={false}
        cropBoxMovable={false}
        cropBoxResizable={!fixedCropBox}
        ready={fitImageToCropBox}
      />
    );
  }
);
