import { useUnit } from 'effector-react';
import { CircularProgress } from '@mui/material';
import { $isLoading } from '../../model/upload.model';

export const Loader = () => {
  const [isLoading] = useUnit([$isLoading]);

  if (!isLoading) return null;

  return <CircularProgress size={40} />;
};
