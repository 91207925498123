import { useEffect, useRef, useState } from 'react';
import { useUnit } from "effector-react";
import CircularProgress from '@mui/material/CircularProgress';
import { CustomScrollbar, Loader } from '@ui/index';

import {
  $news,
  $isLoading,
  searchForm,
  $isAllowNextPage,
  $isFetchByParams,
  getNextPage,
  openNewsCard,
  sendUnshiftNewsFeed,
  type NewsFeedResponse
} from '../../models';
import { PreviewCard, EmptyList, EmptyFound } from '../../molecules';
import { useInfinityScroll } from '../../libs/useInfinityScroll';

import { StyledNewsList, StyledWrapCircularProgress, StyledList } from './styled';

type Props = {
  scrollTop: number;
  setScrollTop: (scrollTop: number) => void;
};

let timeout: ReturnType<typeof setTimeout>;

export const NewsList = ({ scrollTop, setScrollTop }: Props) => {
  const { dirty } = useUnit(searchForm);
  const [
    news,
    isLoading,
    isAllowNextPage,
    isFetchByParams
  ] = useUnit([
    $news,
    $isLoading,
    $isAllowNextPage,
    $isFetchByParams
  ]);
  const listRef = useRef<HTMLDivElement>(null);
  const circularProgressRef = useRef<HTMLDivElement>(null);
  const [newIds, setNewIds] = useState<number[]>([])

  const {
    onScrollStop,
    onScrollFrame,
    isCircularProgress
  } = useInfinityScroll({
    listRef,
    circularProgressRef,
    isAllowNextPage,
    isFetchByParams
  });

  const onClickPreviewCard = (id: number) => {
    if (listRef.current && listRef.current.parentNode) {
      setScrollTop((listRef.current.parentNode as HTMLElement).scrollTop)
    }
    openNewsCard(id);
  };

  const subscribeNewsFeed = ({ news }: NewsFeedResponse) => {
    clearTimeout(timeout);
    if (Array.isArray(news) && news.length > 0) {
      const ids = news.map(({ id }) => id);
      setNewIds(ids);

      timeout = setTimeout(() => {
        setNewIds([]);
      }, 1000);
    }
  }

  useEffect(() => {
    if (isCircularProgress && isAllowNextPage) {
      getNextPage();
    };
  }, [isCircularProgress]);

  useEffect(() => {
    const unSubscribe = sendUnshiftNewsFeed.watch(subscribeNewsFeed);
    if (scrollTop > 0 && listRef.current && listRef.current.parentNode) {
      (listRef.current.parentNode as HTMLElement).scrollTo({
        top: scrollTop
      });
    }
    return unSubscribe;
  }, []);

  if (news.length === 0 && isLoading) return <Loader isLoading={isLoading} />;
  if (news.length === 0 && dirty) return <EmptyFound />;
  if (news.length === 0) return <EmptyList />;

  return (
    <CustomScrollbar onScrollFrame={onScrollFrame} onScrollStop={onScrollStop}>
      <StyledNewsList ref={listRef}>
        <StyledList>
          {news.map((item) => (
            <PreviewCard
              key={item.id}
              {...item}
              isNew={newIds.includes(item.id)}
              onClick={onClickPreviewCard}
            />
          ))}
        </StyledList>
        {isAllowNextPage && (
          <StyledWrapCircularProgress>
            <CircularProgress
              ref={circularProgressRef}
              variant={isCircularProgress ? "indeterminate" : "determinate"}
              value={0}
            />
          </StyledWrapCircularProgress>
        )}
      </StyledNewsList>
    </CustomScrollbar>
  );
};
