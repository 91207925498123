import { useRef } from "react";
import i18n from '@shared/config/i18n';
import { useGate, useUnit } from "effector-react";
import { ThemeAdapter } from '@shared/theme/adapter';
import { IconButton, Badge, Tooltip } from '@mui/material';
import StickyNote2OutlinedIcon from '@mui/icons-material/StickyNote2Outlined';
import { NewsFeedGate, onClickBadge, $unreadCount } from '../model';
import { NewsPreview } from '../organisms';

export const NewsFeedBadge = () => {
  const [unreadCount] = useUnit([$unreadCount]);
  const iconButtonRef = useRef<HTMLButtonElement | null>(null);

  const onClick = () => {
    onClickBadge();
  };

  useGate(NewsFeedGate);

  return (
    <ThemeAdapter>
      <Tooltip title={i18n.t('NewsFeed')}>
        <IconButton onClick={onClick} ref={iconButtonRef}>
          <Badge badgeContent={unreadCount} color="primary">
            <StickyNote2OutlinedIcon />
          </Badge>
        </IconButton>
      </Tooltip>
      <NewsPreview anchorEl={iconButtonRef.current} />
    </ThemeAdapter>
  );
};
