import styled from '@emotion/styled';

export const StyledEmptyList = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0.6;
  height: 100%;
  gap: 12px;
`;