import { useTranslation } from 'react-i18next';
import {
  FormControl,
  FormHelperText,
  FormLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from '@mui/material';
import { Search, Close } from '@mui/icons-material';
import { withControl } from '@features/effector-form/hoc/withControl';

export const SearchControl = withControl(
  ({ value, setValue, error, label, required, readOnly, disabled, reset, isError }) => {
    const { t } = useTranslation();

    const renderEndAdornment = (
      <InputAdornment
        position="end"
        variant="standard"
        style={{ padding: '0 !important' }}
      >
        <>
          {value ? (
            <IconButton onClick={reset} size="small" sx={{ padding: 0 }}>
              <Close />
            </IconButton>
          ) : (
            <Search />
          )}
        </>
      </InputAdornment>
    );
    return (
      <FormControl
        size="medium"
        required={required}
        disabled={disabled}
        style={{ padding: '0 !important' }}
      >
        {label && <FormLabel error={isError}>{label}</FormLabel>}
        <OutlinedInput
          value={value}
          error={isError}
          onChange={(e) => setValue(e.target.value)}
          readOnly={readOnly}
          disabled={disabled}
          placeholder={t('search') ?? 'Поиск'}
          sx={{
            '.MuiOutlinedInput-input': {
              padding: 0,
              height: 40,
              fontWeight: '400 !important',
            },
          }}
          endAdornment={renderEndAdornment}
          style={{ padding: '0 12px' }}
        />
        {error && <FormHelperText error>{error}</FormHelperText>}
      </FormControl>
    );
  }
);
