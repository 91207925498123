import i18n from '@shared/config/i18n';
import { Typography, IconButton, Divider } from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

import { StyledListSubheader, StyledTypography } from './styled';

type Props = {
  onClose: () => void;
}

export const ListSubheader = ({ onClose }: Props) => (
  <>
    <StyledListSubheader component="div" id="nested-list-subheader">
      <StyledTypography variant="subtitle2">{i18n.t('NewsFeed')}</StyledTypography>
      <IconButton disableRipple onClick={onClose} size="small">
        <CloseOutlinedIcon style={{ color: 'white' }} />
      </IconButton>
    </StyledListSubheader>
    <Divider />
  </>
);
