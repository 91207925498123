import { createEffect, createEvent, sample } from 'effector';
import { createGate } from 'effector-react';
import { cache, createQuery, localStorageCache } from '@farfetched/core';
import { signout } from '@features/common';
import { apiRequests } from './api';
import { DataResponse } from './types';

export const ControlGate = createGate();

const fxGetData = createEffect<void, DataResponse, Error>().use(apiRequests.getData);

export const startRequest = createEvent();

export const dataQuery = createQuery({
  name: 'dataStoryFrameButtonSection',
  effect: fxGetData,
  mapData: ({ result }) =>
    result.items.map(({ key, value }) => ({ id: key, label: value })),
});

cache(dataQuery, {
  adapter: localStorageCache({ maxAge: '5m' }),
  purge: signout,
});

sample({
  clock: startRequest,
  target: dataQuery.start,
});
