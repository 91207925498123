import { createGate } from "effector-react";
import { createEvent, createStore } from "effector";

import type { PreviewNews } from './news-feed.types';

export const NewsFeedGate = createGate();

export const onClickBadge = createEvent();
export const onClickPreview = createEvent<number>();
export const updateUnReadCount = createEvent();
export const showPreviewNews = createEvent<PreviewNews[]>();
export const togglePopover = createEvent<boolean>();

export const $isOpenPopover = createStore<boolean>(false);
export const $previewNews = createStore<PreviewNews[]>([]);
