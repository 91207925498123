import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import EmptyListIcon from '@img/empty-list.svg';

import { StyledEmptyList } from './styled';

export const EmptyFound = () => {
  const { t } = useTranslation();
  return (
    <StyledEmptyList>
      <EmptyListIcon />
      <Typography variant="body1">{t('NoNewsFoundMatchingYourRequest')}</Typography>
    </StyledEmptyList>
  )
};
