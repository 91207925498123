import { useTranslation } from 'react-i18next';
import { allSettled } from 'effector';
import { useUnit } from 'effector-react';
import { DialogActions, DialogContent, DialogContentText } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { $error, clearError, createScope } from '../../model/upload.model';

export const ErrorMessage = ({ id }: { id: string }) => {
  const { t } = useTranslation();
  const [error] = useUnit([$error]);

  const handleClearError = async () => {
    const scope = createScope(id);

    await allSettled(clearError, { scope });
  };

  return (
    <Dialog onClose={handleClearError} open={!!error}>
      <DialogTitle>{t('Error')}</DialogTitle>

      <DialogContent>
        <DialogContentText>{error?.message ?? t('DataLoadingError')}</DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClearError}>{t('close')}</Button>
      </DialogActions>
    </Dialog>
  );
};
