import { api } from '@api/api2';

import type { UnreadCountPayload, UnreadCountResponse } from '../model/unread-count';

const getUnreadCount = (payload: UnreadCountPayload): Promise<UnreadCountResponse> => {
  return api.v1('get', 'bms/news/unread-count', payload)
};

export const newsFeedBadge = {
  getUnreadCount
}
