import styled from '@emotion/styled';
import { ListItemAvatar, Popper, ListItemText, Paper } from '@mui/material';

export const StyledPopover = styled(Popper)(({ theme }) => ({
  width: '320px',
  color: 'white',
  padding: 0,
  zIndex: 1000,
  marginRight: '-12px !important',
  "&::before": {
    content: '""',
    position: "absolute",
    top: -14,
    right: 25,
    width: 14,
    height: 14,
    backgroundColor: theme.palette.primary.light,
    clipPath: "polygon(50% 45%, 0% 100%, 100% 100%)",
},
}));

export const StyledPaper = styled(Paper)(({ theme }) => ({
  color: 'white',
  backgroundColor: theme.palette.primary.light,
}));

export const StyledListItemAvatar = styled(ListItemAvatar)`
  img {
    width: 40px;
    height: 40px;
    border-radius: 4px;
  }
`;

export const StyledListItemText = styled(ListItemText)`
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
`;
