import Cropper from 'react-cropper';
import { styled } from '@mui/material';

export const NotFixedCropper = styled(Cropper)(() => ({
  '& .cropper-view-box': {
    outline: '1px solid transparent',
  },
  '& .cropper-line': {
    display: 'none',
  },
  '& .cropper-point': {
    background: '#007bff',
  },
  '& .cropper-point.point-se': {},
}));

export const FixedCropper = styled(Cropper)(() => ({
  '& .cropper-view-box': {
    outline: '1px solid transparent',
  },
  '& .cropper-line': {
    display: 'none',
  },
  '& .cropper-point': {
    display: 'none',
  },
  '& .cropper-point.point-se': {
    display: 'none',
  },
}));
