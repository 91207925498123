import { sample, merge } from 'effector';
import { onClickPreview, updateUnReadCount } from '@features/news-feed-badge';
import {
  PageGate,
  getNextPage,
  fxGetNewsListByParams,
  fxGetNewsListByInterval,
  fxGetNewsRead,
  openNewsCard,
  toggleNewsFeed,
  $isOpenNewsFeed,
  $isAllowNextPage,
  setIsAllowNextPage,
  $news,
  $total,
  $params,
  $isLoading,
  $selectedNews,
  setNewsFeed,
  pushNewsFeed,
  $isFetchByParams,
  unshiftNewsFeed,
  sendUnshiftNewsFeed,
  updateReadStatusById,
  resetParams
} from './news-feed.model';
import { searchForm, searchChanged, resetSearch, type SearchForm } from '../search-form';
import { formatingCreatedAt } from '../../libs';
import { DEFAULT_PARAMS } from './news-feed.constants';

import type {
  News,
  Params,
  NewsFeedResponse,
  NewsReadResponseEffect
} from './news-feed.types';

$isOpenNewsFeed.on(toggleNewsFeed, (_, isOpenNewsFeed) => isOpenNewsFeed)

$news
  .on(setNewsFeed, reducerSetNewsFeed)
  .on(pushNewsFeed, reducerPushNewsFeed)
  .on(unshiftNewsFeed, reducerUnshiftNewsFeed)
  .on(updateReadStatusById, reducerUpdateReadStatusById)
  .reset([PageGate.close, resetSearch]);

$total
  .on(merge([setNewsFeed, pushNewsFeed, unshiftNewsFeed]), reduceUpdateTotal)
  .reset([PageGate.close]);

$isLoading
  .on(fxGetNewsListByParams.pending, (_, pending) => pending)
  .reset($params);

$params
  .on(searchChanged, reduceUpdateParamsBySearch)
  .on(getNextPage, reduceUpdateParamsByNexPage)
  .reset([PageGate.close, resetParams]);

$isAllowNextPage
  .on(setIsAllowNextPage, (_, isAllow) => isAllow)
  .reset([PageGate.close]);

$isFetchByParams
  .on(fxGetNewsListByParams.done, () => true)
  .reset($params);

sample({
  clock: $params,
  target: fxGetNewsListByParams
});

/* если форма поиска грязная, то устанавливаю новый массив с записями */
sample({
  clock: fxGetNewsListByParams.done,
  source: searchForm.$dirty,
  filter: (dirty) => dirty,
  fn: (_, { result }) => result,
  target: setNewsFeed
});

/* если форма поиска чистая, считаю что это пагинация и пушу записи в массив */
sample({
  clock: fxGetNewsListByParams.done,
  source: searchForm.$dirty,
  filter: (dirty) => !dirty,
  fn: (_, { result }) => result,
  target: pushNewsFeed
});

/* если по интервалу пришли новые записи, то добавляю их в начало массива */
sample({
  clock: sendUnshiftNewsFeed,
  target: unshiftNewsFeed
})

sample({
  clock: [openNewsCard, onClickPreview],
  fn: (news) => ({ news: [news] }),
  target: fxGetNewsRead
});

sample({
  clock: fxGetNewsRead.done,
  target: [updateUnReadCount, updateReadStatusById]
});

sample({
  clock: [openNewsCard, onClickPreview],
  source: $news,
  fn: (news, id): News => news.find((item) => item.id === id)!,
  target: $selectedNews
});

/* если количество загруженных новостей меньше $total,
  показываю возможность дозагрузки новых записей */
sample({
  clock: $total,
  source: $news,
  fn: (news, total) => Boolean(news.length < total),
  target: setIsAllowNextPage
});

/* если запрос вернулся с ошибкой, больше не показываю лоадер с загрузкой новостей */
sample({
  clock: fxGetNewsListByParams.fail,
  fn: () => false,
  target: setIsAllowNextPage
})

/** 
 * Редьюсеры по обновлению сторов 
 * Чистые, умытые. Без сайд эффектов
*/

function reduceUpdateParamsByNexPage(params: Params) {
  return {
    ...params,
    page: Number(params.page ?? 1) + 1,
  }
}

function reduceUpdateTotal(_: number, payload: NewsFeedResponse) {
  return payload.meta.total
}

function reduceUpdateParamsBySearch(params: Params, { search }: SearchForm) {
  return {
    ...params,
    ...DEFAULT_PARAMS,
    search,
  }
}

function reducerUpdateReadStatusById(news: News[], payload: NewsReadResponseEffect) {
  const [id] = payload.params.news;
  return news.map((item) => id === item.id
    ? ({ ...item, is_read: true })
    : item
  )
}

function reducerUnshiftNewsFeed(news: News[], payload: NewsFeedResponse) {
  const newsFeed = mappingNewsFeed(payload.news);
  return newsFeed.concat(news);
}

function reducerPushNewsFeed(news: News[], payload: NewsFeedResponse) {
  const newsFeed = mappingNewsFeed(payload.news);
  return news.concat(newsFeed);
}

function reducerSetNewsFeed(_: News[], payload: NewsFeedResponse) {
  return mappingNewsFeed(payload.news);
}

function mappingNewsFeed(news: News[]) {
  return news.map(({ created_at, ...news }) => ({
    ...news,
    created_at: formatingCreatedAt(created_at)
  }));
}


