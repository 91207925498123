import { api } from '@api/api2';

import type {
  NewsFeedPayload,
  NewsFeedResponse,
  NewsReadPayload,
  NewsReadResponse
} from '../models';

const getNewsList = (payload: NewsFeedPayload): Promise<NewsFeedResponse> => {
  return api.v1('get', 'bms/news/list', payload)
};

const postNewsRead = (payload: NewsReadPayload): Promise<NewsReadResponse> => {
  return api.v1('post', 'bms/news/read', payload)
};

export const apiNewsFeed = {
  getNewsList,
  postNewsRead
};
