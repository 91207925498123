import { Hover } from '../hover';
import { Loader } from '../loader';

export const LoaderHover = ({
  visible,
  value,
  id,
  setValue,
}: {
  visible: boolean;
  value: string | null;
  id: string;
  setValue: (value: any) => void;
}) => (
  <>
    <Loader />

    <Hover visible={visible} value={value} id={id} setValue={setValue} />
  </>
);
