import { api } from '@api/api2';
import { UploadFilePayload, UploadFileResponse } from './types';

const uploadFile = (payload: UploadFilePayload) => {
  const response = api.v2('/system/files/save', payload, {}, false);

  return response ?? ({ file: {} } as UploadFileResponse);
};

export const apiRequests = { uploadFile };
