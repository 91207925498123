import { styled } from '@mui/material/styles';
import { DatePicker as DatePickerMui } from '@mui/x-date-pickers-pro';

export const DatePicker = styled(DatePickerMui)(() => ({
  '& .MuiIconButton-edgeEnd': {
    marginRight: '-7px',
  },
  '& input': {
    textTransform: 'lowercase',
  },
  '& input::placeholder': {
    textTransform: 'lowercase',
  },
}));
