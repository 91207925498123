import { alpha, Avatar, FormControl, styled } from '@mui/material';

export const Dropzone = styled('label')(() => ({
  display: 'inline-block',
  width: '100%',
  height: '100%',
  position: 'relative',
  overflow: 'hidden',
  borderRadius: '4px',
  cursor: 'pointer',
  '& .dz-preview': {
    display: 'none',
  },
}));

export const EmptyDropzone = styled('div')<{ notActive?: number; error?: number }>(
  ({ notActive, theme, error }) => ({
    width: '100%',
    height: '100%',
    border: `2px dashed ${
      !!error ? theme.palette.error.main : alpha(theme.palette.primary.main, 0.5)
    }`,
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    opacity: !!notActive ? 0.4 : 1,
  })
);

export const CustomFormControl = styled(FormControl)`
  font-size: 0;
  display: flex;
  flex-direction: column;
`;

export const CustomAvatar = styled(Avatar)(() => ({
  width: '100%',
  height: '100%',
  border: '1px solid #ccc',
  '& .MuiAvatar-img': {
    objectFit: 'contain',
  },
}));

export const Form = styled('form')`
  display: inline-block;
`;
