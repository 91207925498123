import { useRef } from 'react';
import en from 'date-fns/locale/en-US';
import ru from 'date-fns/locale/ru';
import { FormControl, FormHelperText, FormLabel, TextField } from '@mui/material';
import { DateTimePickerProps, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import { enUS } from '@mui/x-date-pickers/locales/enUS';
import { withControl } from '@features/effector-form/hoc/withControl';
import * as Styled from './styled';

const EN = 'en';
const LNG = localStorage.getItem('lang') || EN;

export const DateTimePickerControl = withControl<DateTimePickerProps<any>>(
  ({ value, setValue, error, label, required, readOnly, disabled, disablePast }) => {
    const inputRef = useRef(null);

    const showError = !readOnly && !!error;

    return (
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={LNG === EN ? en : ru}
      >
        <FormControl required={required} disabled={disabled} error={showError}>
          <FormLabel>{label}</FormLabel>

          <Styled.DateTimePicker
            value={value}
            onChange={(newValue) => setValue(newValue)}
            inputRef={inputRef}
            slotProps={{
              field: { clearable: true },
              textField: {
                error: showError,
              },
            }}
            readOnly={readOnly}
            disabled={disabled}
            disablePast={disablePast}
            ampm={LNG === EN ? true : undefined}
            localeText={
              LNG === EN
                ? enUS.components.MuiLocalizationProvider.defaultProps.localeText
                : undefined
            }
          />
          <FormHelperText error>{error}</FormHelperText>
        </FormControl>
      </LocalizationProvider>
    );
  }
);
