import MarkdownToJSX from 'markdown-to-jsx';
import { theme } from '@unicorn/bms-theme';

import { Avatar, ListItem, Typography } from '@mui/material';
import StickyNote2OutlinedIcon from '@mui/icons-material/StickyNote2Outlined';
import type { News } from '../../models';
import {
  StyledListItemText,
  StyledListItemAvatar,
  StyledPreviewContent,
  StyledListItemButton,
  StyleBadge,
} from './styled';

type Props = News & {
  isNew: boolean;
  onClick: (ids: number) => void;
}

export const PreviewCard = ({
  id,
  title,
  isNew,
  preview,
  is_read,
  created_at,
  cover_image_url,
  onClick
}: Props) => {

  const onClickItem = (id: number) => () => {
    onClick(id);
  };

  return (
    <ListItem
      disablePadding
      onClick={onClickItem(id)}
      secondaryAction={(
        <Typography variant="caption">{created_at}</Typography>
      )}
    >
      {!is_read && <StyleBadge />}
      <StyledListItemButton disableRipple selected={!isNew && !is_read} isNew={isNew}>
        <StyledListItemAvatar style={!cover_image_url ? { backgroundColor: theme.palette.action.active } : {}}>
        {cover_image_url
          ? <Avatar src={cover_image_url} variant="rounded" />
          : <Avatar variant="rounded"><StickyNote2OutlinedIcon /></Avatar>}
        </StyledListItemAvatar>
        <StyledListItemText
          primary={title}
          secondary={
            <StyledPreviewContent>
              <MarkdownToJSX>{preview}</MarkdownToJSX>
            </StyledPreviewContent>
          }
        />
      </StyledListItemButton>
    </ListItem>
  );
}