import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Provider, useUnit } from 'effector-react';
import { Button, FormHelperText, FormLabel, OutlinedInputProps } from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { withControl } from '@features/effector-form/hoc/withControl';
import {
  CustomControlProps,
  GeneralControlProps,
} from '@features/effector-form/types/control';
import { ErrorMessage } from './components/error';
import { Loader } from './components/loader';
import { ShowUploadedFile } from './components/showUploadedFile';
import { handleUploadFile } from './helpers/uploadFile';
import { $isLoading, $uploadedFile, ControlGate, createScope } from './model';
import * as Styled from './styled';
import { AdditionalTypes } from './types';

export const UploadSingleFileControlScoped = ({
  label,
  value,
  setValue,
  error,
  required,
  readOnly,
  disabled,
  fileTypes,
  helperText,
  size,
  id,
}: GeneralControlProps & CustomControlProps<OutlinedInputProps & AdditionalTypes>) => {
  const [uploadedFile, isLoading] = useUnit([$uploadedFile, $isLoading]);
  const fileInput = useRef(null);
  const [t] = useTranslation();

  useEffect(() => {
    if (!uploadedFile) return;

    setValue(`${uploadedFile.url}?name=${encodeURI(uploadedFile.name)}`);
  }, [uploadedFile]);

  return (
    <>
      <ControlGate />

      <ErrorMessage id={id} />

      <Styled.CustomFormControl>
        <FormLabel required={!readOnly && required}>{label}</FormLabel>

        <Styled.Form>
          <Loader />

          {!value && !isLoading && (
            <Button
              startIcon={<AttachFileIcon />}
              size={size}
              variant="outlined"
              onClick={() => {
                if (!fileInput.current) return;

                (fileInput.current as HTMLButtonElement).click();
              }}
            >
              {t('Attach')}
            </Button>
          )}

          {value && !isLoading && (
            <ShowUploadedFile value={value} setValue={setValue} readOnly={readOnly} />
          )}

          {!disabled && !readOnly && (
            <input
              type="file"
              ref={fileInput}
              onChange={(event) => handleUploadFile(id, event.target.files, fileTypes)}
              style={{ display: 'none' }}
              id={`file-input_${id}`}
              onClick={(event) => {
                (event.target as HTMLInputElement).value = '';
              }}
            />
          )}
        </Styled.Form>

        {helperText && !readOnly && <FormHelperText>{helperText}</FormHelperText>}

        {error && <FormHelperText error>{error}</FormHelperText>}
      </Styled.CustomFormControl>
    </>
  );
};

export const UploadSingleFileControl = withControl<OutlinedInputProps & AdditionalTypes>(
  (props) => {
    const scope = createScope(props.id);

    return (
      <Provider value={scope}>
        <UploadSingleFileControlScoped {...props} />
      </Provider>
    );
  }
);
