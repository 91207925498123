import en from 'date-fns/locale/en-US';
import ru from 'date-fns/locale/ru';
import { FormControl, FormHelperText, FormLabel } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import { enUS } from '@mui/x-date-pickers/locales/enUS';
import { withControl } from '@features/effector-form/hoc/withControl';
import * as Styled from './styled';

type AddedProps = {
  disableFuture?: boolean;
  disablePast?: boolean;
  slotProps?: Record<string, unknown>;
};

const EN = 'en';
const LNG = localStorage.getItem('lang') || EN;

export const DatePickerControl = withControl<AddedProps>(
  ({ value, setValue, error, label, required, readOnly, disabled, ...rest }) => {
    const showError = !readOnly && !!error;

    return (
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={LNG === EN ? en : ru}
      >
        <FormControl required={required} disabled={disabled} error={showError}>
          <FormLabel>{label}</FormLabel>

          <Styled.DatePicker
            value={value}
            onChange={(newValue) => setValue(newValue)}
            readOnly={readOnly}
            disabled={disabled}
            slotProps={{
              field: { clearable: true },
              textField: {
                error: showError,
              },
            }}
            localeText={
              LNG === EN
                ? enUS.components.MuiLocalizationProvider.defaultProps.localeText
                : undefined
            }
            {...rest}
          />

          <FormHelperText error>{error}</FormHelperText>
        </FormControl>
      </LocalizationProvider>
    );
  }
);
