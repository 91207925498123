import styled from '@emotion/styled';
import List from '@mui/material/List';

export const StyledNewsList = styled('div')``;

export const StyledWrapCircularProgress = styled('div')`
  text-align: center;
  padding-top: 24px;
  padding-bottom: 24px;
`;

export const StyledList = styled(List)`
  scroll-snap-type: y mandatory;
  padding-top: 16px;
`;
