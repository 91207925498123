import { createEvent } from 'effector';
import { createForm } from '@unicorn/effector-form';

import type { SearchForm } from './search-form.types';

export const searchChanged = createEvent<SearchForm>();
export const resetSearch = createEvent<SearchForm>();

export const searchForm = createForm<SearchForm>({
  initialValues: {
    search: '',
  },
  editable: true,
  // resetOn: resetSearch
});

