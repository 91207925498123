import { interval } from 'patronum';
import { sample, merge } from 'effector';
import { searchChanged, searchForm } from '../search-form';
import { fxGetNewsListByInterval, fxGetNewsListByParams, getNewsListByInterval, $params } from './news-feed.model';

/* Интеравал подписки на новости */

const SECOND = 1000;
const TIMEOUT = SECOND * 60;

const stopInterval = merge([
  searchChanged,
  fxGetNewsListByInterval.fail
]);

const { tick } = interval({
  timeout: TIMEOUT,
  start: getNewsListByInterval,
  stop: stopInterval,
});

sample({
  clock: tick,
  source: $params,
  target: fxGetNewsListByInterval
});

/* если в строку поиска введен запрос, или уже ушел запрос на получение записей по параметрам,
  то останавливаю интервал
*/
sample({
  clock: fxGetNewsListByInterval.done,
  source: [searchForm.$dirty, fxGetNewsListByParams.pending],
  filter: ([dirty, pending]) => !dirty || pending,
  target: getNewsListByInterval,
});
