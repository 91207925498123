import { useState, useEffect } from 'react';
import { useGate, useUnit } from 'effector-react';
import { ThemeAdapter } from '@shared/theme/adapter';
import { PageGate, toggleNewsFeed, $isOpenNewsFeed, $mode, Mode } from '../models';
import { ToolbarList } from '../molecules';
import { NewsList, NewsCard } from '../organisms';

import { StyledDrawer } from './styled';

export const NewsFeed = () => {
  const [mode, isOpenNewsFeed] = useUnit([$mode, $isOpenNewsFeed]);
  const [scrollTop, setScrollTop] = useState<number>(0);

  const onCloseDrawer =  () => {
    toggleNewsFeed(false);
  };

  useGate(PageGate);

  useEffect(() => {
    if (!isOpenNewsFeed && scrollTop > 0) {
      setScrollTop(0);
    }
  }, [isOpenNewsFeed]);

  return (
    <ThemeAdapter>
      <StyledDrawer open={isOpenNewsFeed} onClose={onCloseDrawer} anchor="right">
        {mode === Mode.NEWSLIST && (
          <>
            <ToolbarList onClose={onCloseDrawer} />
            <NewsList scrollTop={scrollTop} setScrollTop={setScrollTop} />
          </>
        )}
        {mode === Mode.NEWSCARD && (
          <NewsCard onClose={onCloseDrawer} />
        )}
      </StyledDrawer>
    </ThemeAdapter>
  )
}