import { useGate, useUnit } from 'effector-react';
import {
  Autocomplete,
  AutocompleteProps,
  AutocompleteRenderInputParams,
  Chip,
  CircularProgress,
  FormControl,
  FormHelperText,
  FormLabel,
} from '@mui/material';
import TextField from '@mui/material/TextField';
import { Listbox } from '@features/effector-form/components/listbox';
import { withControl } from '@features/effector-form/hoc/withControl';
import { ControlGate, dataQuery } from './model';
import { Option } from './types';

type CusromProps = {
  excludeIds?: string[]; // Исключить id из options
};

export const StoryStatusControl = withControl<
  Partial<AutocompleteProps<any, any, any, any>> & CusromProps
>(
  ({
    label,
    value,
    setValue,
    error,
    required,
    readOnly,
    disabled,
    multiple,
    excludeIds,
  }) => {
    useGate(ControlGate);

    const { data, pending } = useUnit(dataQuery);

    const handleChange = (event: any, newValue: any) => {
      if (multiple) {
        setValue(newValue.map((item: Option) => item.id) || null);
      } else {
        setValue(newValue?.id || null);
      }
    };

    const getValue = () => {
      if (value === null) return value;

      if (!data && multiple) return [];
      if (!data && !multiple) return null;

      return multiple
        ? data?.filter((item) => value?.indexOf(item.id) > -1)
        : data?.find((item) => item.id === value);
    };

    const resolveEndAdornment = (params: AutocompleteRenderInputParams) => {
      if (readOnly) return null;

      if (pending) return <CircularProgress size={20} color="inherit" />;

      return params.InputProps.endAdornment;
    };

    const options = () => {
      if (!data) return [];

      if (excludeIds) return data.filter(({ id }) => !excludeIds.includes(id));

      return data;
    };

    return (
      <FormControl>
        <FormLabel required={required}>{label}</FormLabel>

        <Autocomplete
          loading={pending}
          value={getValue()}
          options={options()}
          onChange={handleChange}
          ListboxComponent={Listbox}
          renderInput={(params) => (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                required,
                readOnly,
                disabled,
                endAdornment: resolveEndAdornment(params),
              }}
            />
          )}
          readOnly={readOnly}
          disabled={disabled}
          multiple={multiple}
        />

        <FormHelperText error>{error}</FormHelperText>
      </FormControl>
    );
  }
);
