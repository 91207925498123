import { useStore } from 'effector-react';
import { IconButton } from '@ui';
import { Typography, Paper, useMediaQuery, IconButton as MuiIconButton, Badge } from '@mui/material';
import { ArrowBack, Menu, MenuOpen } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { SectionDescriptionButton } from '@features/common';
import { $currentPage, switchMenuFixed, $isFixed } from '@features/navbar/models';
import { ThemeAdapter } from '@shared/theme/adapter';
import { NewsFeedBadge } from '@features/news-feed-badge';
import Notifications from '@features/notifications';
import { ProfileLink } from '@features/profile-link';
import ProfileMenu from '@features/profile-menu';
import i18n from '@shared/config/i18n';

const { t } = i18n;

const useStyles = makeStyles(() => {
  return {
    header: {
      display: 'grid',
      gridTemplateColumns: '1fr auto',
      padding: '20px 24px 8px 24px',
      zIndex: 1000,
    },
    header__content: {
      display: 'flex',
      alignItems: 'center',
      alignContent: 'center',
    },
    header__hamburgerButton: {
      marginRight: 19,
      marginLeft: 5,
    },
    header__hamburgerIcon: {
      cursor: 'pointer',
      color: 'rgba(37, 40, 52, 0.6)',
    },
    header__backButton: {
      marginRight: 10,
    },
    header__backIcon: {
      cursor: 'pointer',
      zIndex: '21',
      color: 'rgba(37, 40, 52, 0.6)',
    },
    header__title: {
      fontFamily: 'Roboto',
      color: 'rgba(37, 40, 52, 0.87)',
      fontSize: '24px',
      fontWeight: '600',
    },
    header__menu: {
      background: 'none',
      boxShadow: 'none',
      color: '#65657B',
      display: 'flex',
      gap: 4,
      alignItems: 'center',
      justifyContent: 'end',
      justifyItems: 'end',
      justifySelf: 'end',
    },
  };
});

export function Header() {
  const sectionHeader = useStore($currentPage);
  const isUpperSm = useMediaQuery((theme) => theme.breakpoints.up('md'));
  const isFixed = useStore($isFixed);
  const classes = useStyles();

  return (
    <header className={classes.header}>
      {sectionHeader && (
        <div className={classes.header__content}>
          <div className={classes.header__hamburgerButton}>
            <IconButton onClick={switchMenuFixed} size="large">
              {isFixed ? (
                <MenuOpen className={classes.header__hamburgerIcon} />
              ) : (
                <Menu className={classes.header__hamburgerIcon} />
              )}
            </IconButton>
          </div>

          {sectionHeader.back && (
            <IconButton
              className={classes.header__backButton}
              title={t('Back')}
              onClick={sectionHeader.back}
              size="large"
            >
              <ArrowBack
                className={classes.header__backIcon}
                fontSize={isUpperSm ? 'large' : 'default'}
              />
            </IconButton>
          )}
          <Typography variant="h2" className={classes.header__title}>
            {sectionHeader.title}
          </Typography>
          <SectionDescriptionButton />
        </div>
      )}

      <Paper classes={{ root: classes.header__menu }}>
        <ProfileLink />
        <ThemeAdapter>
          <NewsFeedBadge />
        </ThemeAdapter>
        <Notifications />
        <ProfileMenu />
      </Paper>
    </header>
  );
}
