import { sample } from 'effector';

import {
  NewsFeedGate,
  updateUnReadCount,
  $isOpenPopover,
  togglePopover,
  showPreviewNews,
  $previewNews,
  onClickPreview
} from './news-feed.model';
import { fxGetUnreadCount, type Lang } from '../unread-count';

$isOpenPopover
  .on(togglePopover, (_, isOpen) => isOpen)
  .reset([onClickPreview]);

$previewNews
  .on(showPreviewNews, (_, news) => news)
  .reset([onClickPreview]);

sample({
  clock: showPreviewNews,
  fn: () => true,
  target: togglePopover
});

sample({
  clock: [NewsFeedGate.open, updateUnReadCount, showPreviewNews],
  fn: () => ({
    lang: localStorage.getItem('lang') as Lang ?? 'ru'
  }),
  target: fxGetUnreadCount
})
