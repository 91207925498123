import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { allSettled } from 'effector';
import { useUnit } from 'effector-react';
import { DialogActions, DialogContent, FormHelperText, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import {
  $editorConfig,
  ControlEritorGate,
  resetEditorConfig,
} from '../../model/editor.model';
import { createScope, startUploadFile } from '../../model/upload.model';
import { ImageCropper } from '../cropper';
import * as Styled from './styled';

export const ImageEditor = ({ id }: { id: string }) => {
  const { t } = useTranslation();
  const cropperRef = useRef();
  const [editorConfig] = useUnit([$editorConfig]);

  if (!editorConfig) return null;

  const {
    active,
    file,
    fixedCropBox,
    width,
    height,
    cropWidth,
    cropHeight,
    aspectRatio,
    initialAspectRatio,
    zoomTo,
    fitZoom,
    helperText,
  } = editorConfig;

  if (!file) return;

  const scope = createScope(id);

  const handleCloseImageEditor = async () => {
    await allSettled(resetEditorConfig, { scope });
  };

  const uploadFile = async (file: File) => {
    await allSettled(startUploadFile, { scope, params: file });
  };

  const startFileUpload = () => {
    if (!cropperRef.current) return;

    (cropperRef.current as any).startFileUpload();
    handleCloseImageEditor();
  };

  return (
    <>
      <ControlEritorGate />

      <Dialog onClose={handleCloseImageEditor} open={!!(active && file)} maxWidth="md">
        <DialogTitle>{t('LoadingImage')}</DialogTitle>

        <Styled.DialogContent>
          <Typography variant="body1">{t('SelectArea')}:</Typography>

          <div>
            <ImageCropper
              ref={cropperRef}
              file={file}
              fixedCropBox={fixedCropBox}
              width={width}
              height={height}
              cropWidth={cropWidth}
              cropHeight={cropHeight}
              aspectRatio={aspectRatio}
              initialAspectRatio={initialAspectRatio}
              zoomTo={zoomTo}
              fitZoom={fitZoom}
              uploadFile={uploadFile}
            />
            {helperText && <FormHelperText>{helperText}</FormHelperText>}
          </div>
        </Styled.DialogContent>

        <DialogActions>
          <Button onClick={startFileUpload} variant="contained">
            {t('Save')}
          </Button>

          <Button onClick={handleCloseImageEditor} variant="outlined">
            {t('close')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
